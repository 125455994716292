.nav-bar {
  display: flex;
  background: #02075d;
  align-items: center;
  justify-content: space-between;
  padding: 0px 36px;
  height: 54px;
  width: calc(100% - 72px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.right-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-elm {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-right: 24px;
}

.nav-title {
  font-weight: bold;
  font-size: 20px;
  margin-right: 36px;
}

.playMenuButton-menu {
  position: absolute;
  width: 240px;
  z-index: 3;
  margin-left: -180px;
  background: white;
  border-radius: 4px;
  box-shadow: var(--shadow-elevation-medium);
}

.playMenuButton-menu ul {
  padding-left: 0px;
  margin: 0;
}

.playMenuButton-menu ul li a {
  margin-right: 0px;
  text-decoration: none;
}

.playMenuButton-menuItem {
  background: #fff;
  color: black;
  padding: 6px;
  margin-bottom: 0px;
  text-align: right;
  border-radius: 4px;
}

.playMenuButton-menuItem:hover {
  background: #02075d;
  color: white;
}

.language-switch {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.language-switch:hover {
  opacity: 0.8;
}
